import isArray from "lodash/isArray";

const STREAM_SELECT = 0x02;

export const selectStreams = (sources) => {
    if (!isArray(sources)) {
        throw new Error("Please give an array of camera source names to selectStreams");
    }
    const encodedNames = sources.map((s) => new TextEncoder().encode(s));
    // format:
    // | 0x02 | select 1 length | select 1 | select 2 length | ... |
    const outLen = encodedNames.reduce(
        // 1 byte for name size
        (acc, n) => acc + 1 + n.length,
        // 1 byte for STREAM_SELECT
        1
    );
    const out = new Uint8Array(outLen);
    out[0] = STREAM_SELECT;
    let offset = 1;
    for (const n of encodedNames) {
        out[offset++] = n.length;
        out.set(n, offset);
        offset += n.length;
    }
    return out.buffer;
};

export const setAIOnOff = (on) => `gie/${on ? "on" : "off"}`;

export const startRecord = (durationS = 7200) => `record/${durationS}`;

export const stopStreaming = () => "quit";

export const cameras = JSON.parse(process.env.REACT_APP_CAMERAS);
const nullCam = { name: null, source: null };
export const defaultCam = cameras[0] || nullCam;

export const getCamera = ({ name, source }) =>
    cameras.find(({ name: n, source: s }) => n === name || s === source);

// "shutdown" because it's arranged to always start again after a shutdown
export const restartStream = () => ["/ros_media_stream/shutdown"];
