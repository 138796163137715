import { useEffect, useState } from "react";
import styles from "./css/MissionTab.module.css";

import {
    missionRecordStart,
    missionRecordStop,
    missionRunPause,
    missionRunStart,
    missionRunStop,
} from "../lib/protocols/control";
import { callSpotService } from "../lib/service";

import { ReactComponent as IconPause } from "../svg/IconPause.svg";
import { ReactComponent as IconPlay } from "../svg/IconPlay.svg";
import { ReactComponent as IconRecord } from "../svg/IconRecord.svg";
import { ReactComponent as IconStop } from "../svg/IconStop.svg";
import Icon from "./Icon";

import {
    STATE_MISSION_GENERATED,
    STATE_MISSION_GENERATING,
    STATE_MISSION_GENERATING_FAILED,
} from "../lib/state";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import { useMission } from "./MissionProvider";
import RecordSavePrompt from "./RecordSavePrompt";
import Switch from "./Switch";

function VideoButtons({ type, selectedId, disabled = true }) {
    const { missionEnded, missionPaused, recordEnded, missionStarting } = useMission();
    const [enableLog, setEnableLog] = useState(true);
    const { recordState, setRecordState } = useMission();
    const [showFinishRecord, setShowFinishRecord] = useState(false);

    const startMissionRecord = () =>
        callSpotService(...missionRecordStart()).catch((err) => console.error(err));
    const stopMissionRecord = () => {
        callSpotService(...missionRecordStop()).catch((err) => console.error(err));
    };

    const startMissionRun = (id) =>
        callSpotService(...missionRunStart(id, enableLog)).catch((err) => console.error(err));
    const pauseMissionRun = () =>
        callSpotService(...missionRunPause()).catch((err) => console.error(err));
    const stopMissionRun = () =>
        callSpotService(...missionRunStop()).catch((err) => console.error(err));

    useEffect(() => {
        switch (recordState) {
            case STATE_MISSION_GENERATED:
            case STATE_MISSION_GENERATING:
            case STATE_MISSION_GENERATING_FAILED:
                setShowFinishRecord(true);
                break;
            default:
                setShowFinishRecord(false);
        }
    }, [recordState]);

    return (
        <>
            {/* TODO: refactor. this is just too much stuff in one button. please pass icon and onClick handler INTO this component instead of relying on this component to know what to do */}
            {type === "run" && (
                <>
                    <div className={styles["video-btn-wrapper"]}>
                        {missionStarting && (
                            <Icon
                                icon={LoadingSpinner}
                                type="svg"
                                width="1.5rem"
                                height="1.5rem"
                                className={styles.spinner}
                            ></Icon>
                        )}
                        {(missionEnded || missionPaused) && (
                            <button
                                disabled={!selectedId || disabled}
                                onClick={() => startMissionRun(selectedId)}
                            >
                                <Icon icon={IconPlay} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                        {!missionPaused && !missionEnded && (
                            <button
                                disabled={missionPaused || !selectedId || disabled}
                                onClick={() => pauseMissionRun()}
                            >
                                <Icon icon={IconPause} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                        {!missionEnded && (
                            <button
                                disabled={missionEnded || !selectedId || disabled}
                                onClick={() => stopMissionRun()}
                            >
                                <Icon icon={IconStop} type="svg" width="30px" height="30px"></Icon>
                            </button>
                        )}
                    </div>
                    {process.env.NODE_ENV === "development" && (
                        <>
                            <Switch
                                title="Enable Mission Logging"
                                isOn={enableLog}
                                onToggle={() => setEnableLog((enable) => !enable)}
                                disabled={!missionEnded}
                            ></Switch>
                            <p style={{ margin: 0, fontSize: "x-small" }}>
                                note: currently disabled and only visible in dev mode⬆️
                            </p>
                        </>
                    )}
                </>
            )}
            {type === "record" && (
                <div className={styles["video-btn-wrapper"]}>
                    <button
                        onClick={recordEnded ? startMissionRecord : stopMissionRecord}
                        disabled={disabled}
                    >
                        {recordEnded ? (
                            <Icon icon={IconRecord} type="svg" width="32px" height="32px" />
                        ) : (
                            <Icon icon={IconStop} type="svg" width="32px" height="32px" />
                        )}
                    </button>
                    {showFinishRecord && (
                        <RecordSavePrompt
                            onClose={() => {
                                setShowFinishRecord(false);
                                setRecordState(null);
                            }}
                        />
                    )}
                </div>
            )}
        </>
    );
}

export default VideoButtons;
