import React, { useCallback, useContext, useState } from "react";

import { ARM_AUTO_GRIP, ARM_MANIP_MANUAL, MODE_SIT, MODE_STAND } from "../lib/protocols/control";
import { useArmControl } from "./ArmControlProvider";
import { AudioContext } from "./AudioProvider";
import { useControl } from "./RobotControlProvider";
import { RobotMovementContext } from "./RobotMovementProvider";
import { RobotStateContext } from "./RobotStateProvider";
import { useSnack } from "./SnackProvider";

import CameraPanel from "./CameraPanel";
import JoystickWrapper from "./JoystickWrapper";
import Menu from "./Menu";
import MenuButton from "./MenuButton";
import NotificationPanel from "./NotificationPanel";

import { ReactComponent as IconBack } from "../svg/IconBack.svg";
import { ReactComponent as IconCamera } from "../svg/IconCamera.svg";
import { ReactComponent as IconCandyBox } from "../svg/IconCandyBox.svg";
import { ReactComponent as IconLight } from "../svg/IconLight.svg";
import { ReactComponent as IconMic } from "../svg/IconMic.svg";
import { ReactComponent as IconMute } from "../svg/IconMute.svg";
import ArmControlButtonPanel from "./ArmControlButtonPanel";
import { useArmState } from "./ArmStateProvider";
import styles from "./css/BottomBar.module.css";
import cameraMenuStyles from "./css/Menu.module.css";

const hasLight = JSON.parse(process.env.REACT_APP_HAS_LIGHT);
const hasSpeaker = JSON.parse(process.env.REACT_APP_HAS_SPEAKER);

const ButtonsBar = React.memo(function ButtonsBar() {
    // use regular function so that dev tool can display name
    const { setPointerPos, armManipMode, setArmManipMode } = useArmControl();
    const [showNotification, setShowNotification] = useState(false);
    const [showActionMenu, setShowActionMenu] = useState(false);
    const { micOpened, setMicOpen } = useContext(AudioContext);
    const [showCameraMenu, setShowCameraMenu] = useState(false);
    const { light, changeLight } = useControl();

    return (
        <>
            <div className={styles.nav}>
                {hasSpeaker && (
                    <MenuButton
                        icon={micOpened ? IconMic : IconMute}
                        iconType="svg"
                        description={micOpened ? "Mute" : "Open Mic"}
                        btnClass={micOpened ? "active" : ""}
                        action={() => setMicOpen(!micOpened)}
                    ></MenuButton>
                )}
                {armManipMode === ARM_AUTO_GRIP && (
                    <MenuButton
                        icon={IconBack}
                        iconType="svg"
                        description="Back"
                        action={() => {
                            setArmManipMode(ARM_MANIP_MANUAL);
                            setPointerPos(false);
                        }}
                    ></MenuButton>
                )}
                {hasLight && (
                    <MenuButton
                        icon={IconLight}
                        iconType="svg"
                        description="Light"
                        btnClass={(light && "active") || null}
                        action={() => changeLight(!light)}
                    ></MenuButton>
                )}
                <MenuButton
                    icon={IconCamera}
                    iconType="svg"
                    description="Switch Camera"
                    action={() => setShowCameraMenu(true)}
                ></MenuButton>
                <MenuButton
                    icon={IconCandyBox}
                    iconType="svg"
                    description="Menu"
                    action={() => setShowActionMenu(!showActionMenu)}
                ></MenuButton>
                {showActionMenu && <Menu closeMenu={() => setShowActionMenu(false)}></Menu>}
                {showCameraMenu && (
                    <div
                        className={cameraMenuStyles.container}
                        tabIndex={1}
                        ref={(div) => div && div.focus()}
                        onBlur={(e) => {
                            if (!e.currentTarget.contains(e.relatedTarget)) {
                                setShowCameraMenu(false);
                            }
                        }}
                    >
                        <CameraPanel onClose={() => setShowCameraMenu(false)}></CameraPanel>
                    </div>
                )}
                <NotificationPanel
                    closePanel={() => setShowNotification(false)}
                    isShow={showNotification}
                ></NotificationPanel>
            </div>
        </>
    );
});

function BottomBar() {
    const { robotControllable, robotMode } = useContext(RobotStateContext);
    const { changeMovement } = useContext(RobotMovementContext);
    const { inArmControl, changeArmMovement, isAutoGripMode } = useArmControl();
    const { hasArm } = useArmState();
    const { addToast } = useSnack();
    const joystickSize = 140;
    const [isLocked, setIsLocked] = useState(false);
    const leftJoystickProps = {
        className: `${styles.joystick} ${styles["pointer-event-auto"]}`,
        disabled: !robotControllable || robotMode === MODE_SIT || isAutoGripMode,
        // disabled: false,
        enableLockFn: !inArmControl,
        size: joystickSize,
        isLocked: isLocked,
        setIsLocked: (e) => setIsLocked(e),
        onClick: () => {
            if (!robotControllable || robotMode === MODE_SIT)
                addToast({
                    type: "error",
                    message: "Cannot move when the robot is sitting, estopped or powered off",
                });
        },
    };
    const rightJoystickProps = {
        ...leftJoystickProps,
        enableLockFn: robotMode === MODE_STAND,
    };

    const onChangeLeft = useCallback(
        ({ x: y = 0, y: x = 0 }) => {
            // console.log("joystick left: inArmControl:", inArmControl);
            if (hasArm && inArmControl) {
                // console.log("change arm movement...", { x, y });
                changeArmMovement({ position: "leftX", value: y }); // actually x
                changeArmMovement({ position: "leftY", value: 0 - x }); // actually -y
            } else {
                changeMovement({ axis: "x", value: x });
                changeMovement({ axis: "y", value: y });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasArm, inArmControl] // don't depend on a non-memorized function
    );

    const onChangeRight = useCallback(
        ({ x = 0, y = 0 } = {}) => {
            // console.log("joystick right: inArmControl:", inArmControl);
            if (hasArm && inArmControl) {
                changeArmMovement({ position: "rightX", value: x });
                changeArmMovement({ position: "rightY", value: -y });
            } else {
                changeMovement({ axis: "z", value: x });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [hasArm, inArmControl] // don't depend on a non-memorized function
    );

    const joysticksShouldShow = robotMode !== MODE_SIT;

    return (
        <div
            className={`${styles.container} ${styles["pointer-event-none"]}`}
            style={{ minHeight: `${joystickSize}px` }}
        >
            {joysticksShouldShow && (
                <JoystickWrapper {...leftJoystickProps} onChange={onChangeLeft}></JoystickWrapper>
            )}
            <ButtonsBar></ButtonsBar>
            {joysticksShouldShow && (
                <div className={styles["relative"]}>
                    <JoystickWrapper
                        {...rightJoystickProps}
                        onChange={onChangeRight}
                    ></JoystickWrapper>
                    <ArmControlButtonPanel joystickSize={joystickSize} />
                </div>
            )}
        </div>
    );
}

export default BottomBar;
