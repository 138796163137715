import styles from "./css/ProximityAlert.module.css";

import { useMemo } from "react";
import { STATE_STREAM_NONE, STATE_STREAM_OKAY, STATE_STREAM_SLOW } from "../lib/state";
const ProximityBar = ({ distance, direction, ...otherProps }) => {
    // isShow require direction & distance <= 60
    const isShow = direction && distance <= 60 && distance;
    const color = distance <= 44 ? `red` : distance >= 45 && distance <= 60 ? `yellow` : "";
    const barDirection = useMemo(() => {
        switch (direction) {
            case "top":
                return styles[`top-${color}-bar`];
            case "bottom":
                return styles[`bottom-${color}-bar`];
            case "left":
                return styles[`left-${color}-bar`];
            case "right":
                return styles[`right-${color}-bar`];
            default:
                return "";
        }
    }, [direction]);

    return <>{isShow && <div className={`${barDirection ?? ""} `} {...otherProps} />}</>;
};

function ProximityAlert({ camConnected = false, streamStatus, avoidanceList = [] }) {
    // avoidance List should have Object that include direction and distance of obstacle
    /** params
    direction : 'top' || 'bottom' || 'left' || 'right'
     distance : Number
     */
    // { direction:'top', distance: 45 }
    const isShow = useMemo(() => {
        if (!camConnected) return false;
        else {
            switch (streamStatus) {
                case STATE_STREAM_OKAY:
                    return true;
                case STATE_STREAM_SLOW:
                    return true;
                case STATE_STREAM_NONE:
                    return false;
                default:
                    return false;
            }
        }
    }, [camConnected, streamStatus]);

    return (
        <>
            {isShow && (
                <div className={`${styles["container"]}`}>
                    {/* Should not exist 5 directions */}
                    {avoidanceList.length < 5 &&
                        avoidanceList.map((item) => <ProximityBar {...item} />)}
                </div>
            )}
        </>
    );
}

export default ProximityAlert;
