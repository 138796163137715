import { useEffect, useMemo, useState } from "react";
import {
    errToMsg,
    STATES_MISSION_END_STATES,
    STATE_MISSION_FAILED,
    STATE_MISSION_STOPPED,
} from "../lib/state";
import styles from "./css/MissionTab.module.css";
import EntityAbsence from "./EntityAbsence";
import MissionItem from "./MissionItem";
import { useMission } from "./MissionProvider";
import Section from "./Section";

import { includesCaseInsensitive, onClickAndTouchEnd, strCompare } from "../lib/utils";
import DialogBox from "./DialogBox";
import ErrorPopup from "./ErrorPopup";
import SearchBar from "./SearchBar";

function MissionTab() {
    const {
        missionState,
        missionPayload,
        missions,
        listMission,
        clearMissionState,
        setMissionState,
        missionId,
        setMissionId,
    } = useMission();
    const [searchText, setSearchText] = useState("");
    const [selectedSort, setSelectedSort] = useState("time");
    const [isAscending, setIsAscending] = useState({ name: true, time: false });
    const [missionError, setMissionError] = useState(null);
    const [missionCompeleted, setMissionCompeleted] = useState(null);
    const toggleAscending = (sortField) =>
        setIsAscending({
            ...isAscending,
            [sortField]: !!!isAscending[sortField],
        });
    const searchBarProps = {
        isAscending,
        toggleAscending,
        setSelectedSort,
        selectedSort,
    };
    useEffect(() => {
        listMission(true);
    }, []);
    useEffect(() => {
        if (missionState === STATE_MISSION_STOPPED) {
            setMissionCompeleted({
                title: "Mission Completed",
                content: "Mission has completed successfully",
                textConfirm: "Great!",
                textCancel: null,
                onConfirm: () => {
                    setMissionState(null);
                    setMissionCompeleted(null);
                },
            });
        }
        if (missionState === STATE_MISSION_FAILED) {
            const { err } = missionPayload;
            setMissionError({
                title: missionState,
                content: `An error has occured during the mission (${err}): ${errToMsg(err)}`,
                textConfirm: "Okay",
                textCancel: null,
            });
        }
        if (STATES_MISSION_END_STATES.includes(missionState)) {
            clearMissionState();
        }
    }, [missionState, missionPayload, clearMissionState]);

    // the menu that contains rename mission and delete mission buttons
    const search = (array, searchText, fields) => {
        if (!searchText) return array;
        return array.filter(
            (item) =>
                includesCaseInsensitive(item["name"], searchText) ||
                includesCaseInsensitive(item["time"], searchText) ||
                item["name"].includes(missionId)
        );
    };
    const computedMissionLists = useMemo(() => {
        if (!missions) return [];
        // mapped to object to deduplicate efforts when backend changes to objects instead of just plain names
        // normalize missions to have the `created` field renamed as `time`
        const normalized = missions.map(({ name, created }) => ({ name, time: created }));
        return search(normalized, searchText).sort((a, b) =>
            strCompare({ reverse: !isAscending[selectedSort] })(a[selectedSort], b[selectedSort])
        );
    }, [missions, searchText, isAscending, missionId, selectedSort]);

    return (
        <>
            <Section retractable={false}>
                <Section.Header>
                    Mission Record
                    <button
                        className={styles["sm-action-btn"]}
                        {...onClickAndTouchEnd(() => listMission(true))}
                    >
                        (Refresh)
                    </button>
                </Section.Header>
                <Section.Content scrollable={true} className={styles.content}>
                    <SearchBar text={searchText} onChange={setSearchText} {...searchBarProps} />
                    {missions ? (
                        computedMissionLists.length > 0 ? (
                            computedMissionLists.map(({ name, time }) => (
                                <MissionItem
                                    time={time}
                                    isSelected={name === missionId}
                                    key={name}
                                    id={name}
                                    setSelectedId={setMissionId}
                                    name={name}
                                    onClick={() => {
                                        setMissionId(name);
                                    }}
                                />
                            ))
                        ) : (
                            <EntityAbsence
                                title={"No Recorded Mission Yet"}
                                btnText={"Record a Mission"}
                            ></EntityAbsence>
                        )
                    ) : (
                        // skeleton

                        [...Array(5)].map((x, i) => <MissionItem key={i} isLoading={true} />)
                    )}
                </Section.Content>
            </Section>

            {missionError && (
                <ErrorPopup
                    type="error"
                    title={missionError.title}
                    content={missionError.content}
                    onClose={() => {
                        setMissionState(null);
                        setMissionError(null);
                    }}
                ></ErrorPopup>
            )}
            {missionCompeleted && <DialogBox {...missionCompeleted} />}
        </>
    );
}

export default MissionTab;
