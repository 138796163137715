import React, { useContext, useEffect, useMemo, useState } from "react";
import * as CONST from "../consts";
import {
    ARM_MANIP_MANUAL,
    MODE_CRAWL,
    MODE_SIT,
    MODE_STAIR,
    MODE_STAND,
    MODE_WALK,
} from "../lib/protocols/control";
import { cameras } from "../lib/protocols/streams";
import {
    errToMsg,
    STATE_MISSION_GENERATED,
    STATE_MISSION_GENERATING_FAILED,
    STATE_RECORD_FAILED,
} from "../lib/state";
import { onClickAndTouchEnd } from "../lib/utils";
import { useArmControl } from "./ArmControlProvider";
import { useArmState } from "./ArmStateProvider";
import { CameraContext, CAM_VIEW_MAIN } from "./CameraProvider";
import styles from "./css/GeneralTab.module.css";
import DialogBox from "./DialogBox";
import DummyForDev from "./DummyForDev";
import ErrorPopup from "./ErrorPopup";
import { GamepadContext } from "./GamepadProvider";
import { useInspection } from "./InspectionProvider";
import { useMission } from "./MissionProvider";
import RangeSlider from "./RangeSlider";
import { useControl } from "./RobotControlProvider";
import { RobotStateContext } from "./RobotStateProvider";
import Section from "./Section";
import SelectArtPiece from "./SelectArtPiece";
import { useSnack } from "./SnackProvider";
import Switch from "./Switch";
import VideoButtons from "./VideoButtons";

import { useActionState } from "./ActionStateProvider";
import { usePTZControl } from "./PTZControlProvider";

function GeneralTab() {
    const [recordError, setRecordError] = useState(null);
    const [inspectionError, setInspectionError] = useState(null);
    const {
        changeSpeed,
        changeMode,
        changeHeight,
        changeAvoidance,
        changeArmTorque,
        changeGripDepth,
        useKeyboard,
        setUseKeyboard,
        useGamepad,
        setUseGamepad,
        speed,
    } = useControl();
    const { gamepadConnected } = useContext(GamepadContext);
    const { robotControllable, robotMode, robotHeight } = useContext(RobotStateContext);
    const {
        recordState,
        recordPayload,
        handleRename,
        handleDelete,
        inspection,
        inspectionPayload,
    } = useMission();
    const { addToast } = useSnack();
    const { preparing, prepareArtRef, deleteArtRef } = useInspection();
    const [listingArt, setListingArt] = useState(false);

    const disabled = !robotControllable;

    const { inArmControl, toggleArmControl, setArmManipMode } = useArmControl();
    const { hasArm, armGripDepth, armTorque } = useArmState();
    const { selectSource } = useContext(CameraContext);
    const [newMissionId, setNewMissionId] = useState(null);
    const [error, setError] = useState(null);

    const { disableAction, setDisableAction } = useActionState()
    const { setInPTZControl } = usePTZControl()

    useEffect(() => {
        if ([STATE_RECORD_FAILED, STATE_MISSION_GENERATING_FAILED].includes(recordState)) {
            const { err } = recordPayload;
            setRecordError({ title: recordState, content: `${err}: ${errToMsg(err)}` });
        }
        if (recordState === STATE_MISSION_GENERATED) setNewMissionId(recordPayload?.mission_id);
    }, [recordState, recordPayload]);

    useEffect(() => {
        const { success, data } = inspectionPayload;

        if (!success && data) {
            setInspectionError({
                title: inspection,
                content: data.error_msg,
            });
        }

        if (success && data.has_dmg) {
            addToast({
                type: "warning",
                message: "The art piece has damages.",
            });
        }
    }, [inspectionPayload]);

    const robotControlsClassNames = useMemo(
        () => styles[!robotControllable ? "disabled-controls" : ""],
        [robotControllable]
    );
    const showAvoidance = useMemo(
        () => robotMode === MODE_WALK || robotMode === MODE_CRAWL || robotMode === MODE_STAIR,
        [robotMode]
    );
    const recordComplete = {
        title: "Name / Delete this mission",
        textConfirm: "Save",
        textCancel: "Cancel",
        onConfirm: () => handleRename(recordPayload?.mission_id, newMissionId, setError),
        onCancel: () => handleDelete(recordPayload?.mission_id, setError),
    };
    const RECORD_GENERATED = recordState === STATE_MISSION_GENERATED;
    return (
        <>
            <div className={`${styles["control-container"]} ${robotControlsClassNames}`}>
                <Section className={styles.controls}>
                    <Section.Header>Basic Controls</Section.Header>
                    <Section.Content className={styles.content}>
                        <h3 style={{ marginBottom: "8px" }}>Pose</h3>
                        <div className={styles["pose-btn-wrapper"]}>
                            {[
                                { label: "Sit", mode: MODE_SIT },
                                { label: "Stand", mode: MODE_STAND },
                                { label: "Walk", mode: MODE_WALK },
                                { label: "Crawl", mode: MODE_CRAWL },
                                { label: "Stair", mode: MODE_STAIR },
                            ].map(({ label, mode }) => (
                                <button
                                    key={label}
                                    className={
                                        ((!hasArm || !inArmControl) &&
                                            robotMode === mode &&
                                            styles.active) ||
                                        ""
                                    }
                                    {...onClickAndTouchEnd(() => {
                                        if (inArmControl)
                                            selectSource(
                                                CAM_VIEW_MAIN,
                                                cameras.find((item) => item.name === `Front`)
                                                    ?.source
                                            );
                                        toggleArmControl(false);
                                        changeMode(mode);
                                        setArmManipMode(ARM_MANIP_MANUAL);
                                    })}
                                    disabled={disabled}
                                >
                                    {label}
                                </button>
                            ))}
                            {hasArm && (
                                <button
                                    className={(hasArm && inArmControl && styles.active) || ""}
                                    onClick={() => {
                                        toggleArmControl(true);
                                    }}
                                    disabled={disabled}
                                >
                                    Arm
                                </button>
                            )}
                        </div>

                        <div
                            className={`${styles["slider-container"]} ${robotControlsClassNames}`}
                            style={{ marginTop: "25px" }}
                        >
                            <RangeSlider
                                lazy={false}
                                name="speed"
                                unit="m/s"
                                onChange={(value) => changeSpeed(value)}
                                options={{
                                    min: CONST.SPEED_SLOW,
                                    max: CONST.SPEED_FAST,
                                    step: CONST.SPEED_STEP,
                                    current: 0.0,
                                    defaultValue: speed,
                                    controlled: true,
                                }}
                                disabled={disabled}
                            ></RangeSlider>
                            <RangeSlider
                                name="height"
                                unit="m"
                                onChange={(h) => changeHeight(h)}
                                options={{
                                    min: CONST.HEIGHT_MIN,
                                    max: CONST.HEIGHT_MAX,
                                    step: CONST.HEIGHT_STEP,
                                    current: 0.0,
                                    defaultValue: robotHeight,
                                    lazy: false,
                                    controlled: true,
                                }}
                                disabled={disabled}
                            ></RangeSlider>
                            <RangeSlider
                                name="Avoidn."
                                unit="m"
                                onChange={(m) => changeAvoidance(m)}
                                options={{
                                    min: CONST.AVOID_MIN,
                                    max: CONST.AVOID_MAX,
                                    step: CONST.AVOID_STEP,
                                    current: 0.0,
                                    defaultValue: CONST.AVOID_DEFAULT,
                                }}
                                disabled={disabled}
                            ></RangeSlider>
                            {inArmControl && hasArm && (
                                <>
                                    <RangeSlider
                                        name="torque"
                                        unit="N-m"
                                        onChange={(h) => changeArmTorque(h)}
                                        options={{
                                            min: CONST.ARM_TORQUE_MIN,
                                            max: CONST.ARM_TORQUE_MAX,
                                            step: CONST.ARM_TORQUE_STEP,
                                            defaultValue: armTorque,
                                            lazy: false,
                                            controlled: true,
                                        }}
                                        disabled={disabled}
                                    ></RangeSlider>
                                    <RangeSlider
                                        name="depth"
                                        unit="cm"
                                        onChange={(h) => changeGripDepth(h)}
                                        options={{
                                            min: CONST.ARM_GRIP_DEPTH_MIN,
                                            max: CONST.ARM_GRIP_DEPTH_MAX,
                                            step: CONST.ARM_GRIP_DEPTH_STEP,
                                            defaultValue: armGripDepth,
                                            lazy: false,
                                            controlled: true,
                                        }}
                                        disabled={disabled}
                                    ></RangeSlider>
                                </>
                            )}

                            {
                                // not implemented
                                false && (
                                    <RangeSlider
                                        name="light"
                                        onChange={(value) => { }}
                                        options={{
                                            min: CONST.LIGHT_MIN,
                                            max: CONST.LIGHT_MAX,
                                            step: CONST.LIGHT_STEP,
                                            defaultValue: CONST.LIGHT_DEFAULT,
                                        }}
                                        disabled={disabled}
                                    ></RangeSlider>
                                )
                            }
                        </div>
                    </Section.Content>
                </Section>

                <Section className={styles.controls}>
                    <Section.Header>New Record</Section.Header>
                    <Section.Content>
                        <VideoButtons type="record" disabled={disabled} />
                    </Section.Content>
                </Section>

                {CONST.HAS_INSPECTION && (
                    <Section className={styles.controls}>
                        <Section.Header>Addtional Function</Section.Header>
                        <Section.Content>
                            <h3 style={{ marginBottom: "8px" }}>Inspection</h3>
                            <div className={styles["pose-btn-wrapper"]}>
                                <button
                                    className={(preparing && styles.active) || ""}
                                    onClick={prepareArtRef}
                                    disabled={disabled}
                                >
                                    Prepare Art Ref
                                </button>
                                <button
                                    className={(listingArt && styles.active) || ""}
                                    onClick={() => {
                                        setListingArt(true);
                                    }}
                                    disabled={disabled}
                                >
                                    Edit Art references
                                </button>
                            </div>
                        </Section.Content>
                    </Section>
                )}

                <Section className={styles["advanced-control"]} retracted={true}>
                    <Section.Header>Advanced Controls</Section.Header>
                    <Section.Content style={{ margin: "5px auto" }}>
                        <Switch
                            title="Enable Action"
                            isOn={!disableAction}
                            onToggle={() => {
                                if (disableAction)
                                    setInPTZControl(false);
                                setDisableAction(!disableAction);
                            }} />
                        <Switch
                            title="WASD Control"
                            isOn={useKeyboard}
                            onToggle={() => {
                                setUseKeyboard(!useKeyboard);
                            }}
                            disabled={disabled}
                        ></Switch>
                        <div>
                            <Switch
                                title="Gamepad control"
                                isOn={useGamepad}
                                onToggle={() => {
                                    setUseGamepad(!useGamepad);
                                }}
                                disabled={disabled}
                                caption={
                                    useGamepad && gamepadConnected
                                        ? "Connected"
                                        : useGamepad && !gamepadConnected
                                            ? "Disconnected"
                                            : null
                                }
                            ></Switch>
                        </div>
                    </Section.Content>
                </Section>
            </div>

            <div>
                {/* Development only. Does not appear in builds */}
                {process.env.NODE_ENV === "development" && <DummyForDev />}
                {/* end Development only */}
            </div>

            {recordError && (
                <ErrorPopup
                    type="error"
                    title={recordError.title}
                    content={recordError.content}
                    onClose={() => setRecordError(null)}
                ></ErrorPopup>
            )}
            {inspectionError && (
                <ErrorPopup
                    type="error"
                    title={inspectionError.title}
                    content={inspectionError.content}
                    onClose={() => setInspectionError(null)}
                ></ErrorPopup>
            )}
            {listingArt !== false && (
                <SelectArtPiece
                    onClose={(artId) => {
                        if (artId !== null) {
                            deleteArtRef(artId);
                        }
                        console.log("deleted art ", artId);
                        setListingArt(false);
                    }}
                    listArt={true}
                />
            )}
            {RECORD_GENERATED && (
                <DialogBox {...recordComplete}>
                    <div>
                        <input
                            className={styles.input}
                            type={"text"}
                            placeholder={"Mission Name"}
                            value={newMissionId}
                            onChange={(e) => setNewMissionId(e.target.value)}
                        />
                        {error && <span className={`${styles["error-message"]}`}>{error}</span>}
                        <br />
                    </div>
                </DialogBox>
            )}
        </>
    );
}

export default React.memo(GeneralTab);
